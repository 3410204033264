<template>
  <div class="box">
    <div class="left">
      <div class="ratenum">5.0</div>
      <div>信誉度评分</div>
    </div>
    <ul class="centerbox">
      <li>
        <span>名字质量</span>
        <van-rate color="#FDA802" readonly size="16" v-model="rate"></van-rate>
        <span class="raten">5.0</span>
      </li>
      <li>
        <span>取名速度</span>
        <van-rate color="#FDA802" readonly size="16" v-model="rate"></van-rate>
        <span class="raten">4.9</span>
      </li>
      <li>
        <span>服务态度</span>
        <van-rate color="#FDA802" readonly size="16" v-model="rate"></van-rate>
        <span class="raten">5.0</span>
      </li>
    </ul>
    <div class="right">
      <div style="font-size:18px;font-weight:bold">99.86%</div>
      <div style="font-size:12px;color:#7C7B7B;margin-top:8px">综合满意度</div>
    </div>
  </div>
</template>

<script>
import { Rate } from "vant";

export default {
  components: {
    vanRate: Rate,
  },
  data() {
    return {
      rate: 5,
    };
  },
};
</script>

<style lang="scss" scoped>
.box {
  display: flex;
  justify-content: space-between;
    margin-bottom: 35px;
}
.left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  font-size: 12px;
  font-weight: bold;
  color: #7c7b7b;
  line-height: 20px;
  .ratenum {
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #fda802;
    margin-bottom: 7px;
  }
}

.centerbox{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #7C7B7B;
  flex-grow:1;
  li{
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    span{
      margin-right: 5px;
    }
    .raten{
        color: #fda802;
        font-weight: bold;
        font-size: 14px;
    }
  }
}

.right{
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
