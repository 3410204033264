import request from "../utils/request.js";

export const $ekaf = (params) => {//用户评论数据
    return request({
        url:'/ekaf',
        method:'post',
        data:params
    })
}
export const $ekafFcommitRate = (params) => {//用户评论点赞接口
    return request({
        url:'/ekaf/fcommitRate',
        method:'post',
        data:params
    })
}
// export const $orderPPXL = (data) => {
//     return request({
//         url:'/api/order/PPXL',
//         method:'get',
//         params:data
//     })
// }